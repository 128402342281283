export const locale = {
  lang: 'en-US',
  data: {
    MENU: {
      SEND_MONEY: 'Send Money',
      HOW_IT_WORKS: 'How it Works',
      CUSTOMER_SERVICE: 'Get Help',
      CONTACT_US: 'Contact Us',
    },
  },
};
