/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';

import { environment } from 'environments/environment';

import { LocalizationService } from '../../../utils/services/localization.service';

import { locales } from '../../localizations/footer';

/***
 * Footer Component
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public environment = environment;

  @Input() public footerVariant!: string;
  @Input() public hideFooter!: boolean;

  //Get Year
  public year = new Date().getFullYear();

  constructor(private localizationService: LocalizationService) {}

  ngOnInit(): void {
    this.localizationService.translate(...locales);
  }
}
