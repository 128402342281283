export const locale = {
  lang: 'th-TH',
  data: {
    MENU: {
      SEND_MONEY: 'ส่งเงิน',
      HOW_IT_WORKS: 'มีขั้นตอนอย่างไร',
      CUSTOMER_SERVICE: 'ขอความช่วยเหลือ',
      CONTACT_US: 'ติดต่อเรา',
    },
  },
};
