<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="footer-py-60">
          <div class="row">
            <div class="col-lg-4 col-12 mb-0 pb-0 pb-md-2">
              <a href="javascript:void(0)">
                <img
                  src="assets/images/app/short-logo-light.svg"
                  height="42"
                  alt=""
                />
              </a>
              <p class="mt-4" [innerHTML]="'INTRODUCE' | translate"></p>
              <ul
                class="list-unstyled social-icon foot-social-icon d-flex mb-0 mt-4"
              >
                <li class="list-inline-item ms-1 mb-0">
                  <a
                    [href]="environment.socialLink.facebook"
                    class="rounded"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="uil uil-facebook"></i>
                  </a>
                </li>
                <li class="list-inline-item ms-1 mb-0">
                  <a
                    [href]="environment.socialLink.line"
                    class="rounded"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="uil uil-line"></i>
                  </a>
                </li>
                <li class="list-inline-item ms-1 mb-0">
                  <a
                    [href]="environment.socialLink.youtube"
                    class="rounded"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="uil uil-youtube"></i>
                  </a>
                </li>
                <li class="list-inline-item ms-1 mb-0">
                  <a
                    [href]="environment.socialLink.email"
                    class="rounded"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i-feather name="mail"></i-feather>
                  </a>
                </li>
              </ul>

              <div class="app-badge mt-4">
                <a
                  [href]="environment.appLink.appStore"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    class="me-3"
                    src="assets/images/app/app-store-badge.svg"
                    alt=""
                  />
                </a>
                <a
                  [href]="environment.appLink.playStore"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets/images/app/google-play-badge.svg" alt="" />
                </a>
              </div>
            </div>

            <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h5 class="footer-head">{{ 'COMPANY.TITLE' | translate }}</h5>
              <ul class="list-unstyled footer-list mt-4">
                <li>
                  <a href="javascript:void(0)" class="text-foot">
                    <i class="uil uil-angle-right-b rotate-90 me-1"></i
                    >{{ 'COMPANY.CONTACT_US' | translate }}
                    <div class="contact-detail">
                      {{ 'COMPANY.ADDRESS' | translate }}
                    </div>
                    <div class="contact-detail">
                      {{ 'COMPANY.TELEPHONE' | translate }}
                    </div>
                    <div class="contact-detail">
                      {{ 'COMPANY.EMAIL' | translate }}
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h5 class="footer-head">
                {{ 'USEFULL_LINKS.TITLE' | translate }}
              </h5>
              <ul class="list-unstyled footer-list mt-4">
                <li>
                  <a routerLink="/terms" class="text-foot">
                    <i class="uil uil-angle-right-b me-1"></i>
                    {{ 'USEFULL_LINKS.TERMS_AND_CONDITIONS' | translate }}
                  </a>
                </li>
                <li>
                  <a routerLink="/privacy-policy" class="text-foot">
                    <i class="uil uil-angle-right-b me-1"></i>
                    {{ 'USEFULL_LINKS.PRIVACY_POLICY' | translate }}
                  </a>
                </li>
                <li>
                  <a routerLink="/complaint" class="text-foot">
                    <i class="uil uil-angle-right-b me-1"></i>
                    {{ 'USEFULL_LINKS.COMPLAINT' | translate }}
                  </a>
                </li>
                <li>
                  <a
                    [href]="environment.otherLink.complain"
                    class="text-foot"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="uil uil-angle-right-b me-1"></i>
                    {{ 'USEFULL_LINKS.WHISTLEBLOWER' | translate }}
                  </a>
                </li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h5 class="footer-head">{{ 'POWERED_BY.TITLE' | translate }}</h5>
              <img
                class="w-100"
                src="assets/images/illustrator/ripple-net.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-py-30 footer-bar">
    <div class="container text-center">
      <div class="row align-items-center">
        <div class="col-12">
          <div class="text-sm-start">
            <p class="mb-0">
              {{ 'COPYRIGHT' | translate : { year } }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
