/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, Provider } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import {
  catchError,
  combineLatest,
  first,
  map,
  mergeMap,
  Observable,
  throwError,
} from 'rxjs';

import { Store } from '@ngrx/store';

import * as CryptoJS from 'crypto-js';
import * as Forge from 'node-forge';

import { NgRxState } from '../../modules/app-ngrx.module';
import { ContextKey } from '../constants/context';

import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class EventInterceptor implements HttpInterceptor {
  constructor(private ngrxStore: Store<NgRxState>) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return combineLatest([this.ngrxStore.select('App')]).pipe(
      first(),
      mergeMap(() => {
        const index = req.url.indexOf('/assets/');
        if (index !== -1) {
          return next
            .handle(req)
            .pipe(catchError((err) => throwError(() => err)));
        }

        if (
          environment.security.encryption &&
          req.context.get(ContextKey.ApiEncryption)
        ) {
          const key = this.random(32);
          const iv = this.random(16);

          const encrypted = this.aesEncrypt(JSON.stringify(req.body), key, iv);
          const hashKey = this.rsaEncrypt(
            key,
            environment.security.publicKey || ''
          );
          const hashIV = this.rsaEncrypt(
            iv,
            environment.security.publicKey || ''
          );

          req = req.clone({
            setHeaders: {
              ApiVersion: req.context.get(ContextKey.ApiVersion) || '1.0',
              Secret: hashKey.toString(),
              Signature: hashIV.toString(),
            },
            withCredentials: true,
            body: { data: encrypted },
          });

          return next.handle(req).pipe(
            map((res: HttpEvent<any>) => {
              if (res instanceof HttpResponse) {
                const decrypted = this.aesDecrypt(res.body.data, key, iv);

                res = res.clone({
                  body: JSON.parse(decrypted),
                });
              }

              return res;
            }),
            catchError((err: HttpErrorResponse) => {
              const decrypted = err.error
                ? this.aesDecrypt(err.error.data, key, iv)
                : null;

              return throwError(() => {
                new HttpErrorResponse({
                  error: decrypted ? JSON.parse(decrypted) : null,
                  headers: err.headers,
                  status: err.status,
                  statusText: err.statusText,
                  url: err.url || '',
                });
              });
            })
          );
        } else {
          req = req.clone({
            setHeaders: {
              ApiVersion: req.context.get(ContextKey.ApiVersion) || '1.0',
            },
            withCredentials: true,
          });

          return next
            .handle(req)
            .pipe(catchError((err) => throwError(() => err)));
        }
      }),
      catchError((err) => throwError(() => err))
    );
  }

  private aesEncrypt(value: string, key: string, iv: string): string {
    const encrypt = CryptoJS.AES.encrypt(value, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });

    return encrypt.toString();
  }

  private aesDecrypt(value: string, key: string, iv: string): string {
    const decrypt = CryptoJS.AES.decrypt(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      { ciphertext: CryptoJS.enc.Base64.parse(value) },
      CryptoJS.enc.Utf8.parse(key),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return decrypt.toString(CryptoJS.enc.Utf8);
  }

  private rsaEncrypt(value: string, key: string): string | boolean {
    const ras = Forge.pki.publicKeyFromPem(key);

    return window.btoa(ras.encrypt(value));
  }

  private random(length: number): string {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return result;
  }
}

export const EventFetchProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: EventInterceptor,
  multi: true,
};
