export const locale = {
  lang: 'da-DK',
  data: {
    MENU: {
      SEND_MONEY: 'Send penge',
      HOW_IT_WORKS: 'Hvordan det virker',
      CUSTOMER_SERVICE: 'Få hjælp',
      CONTACT_US: 'Kontakt os',
    },
  },
};
