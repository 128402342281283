import {
  APP_INITIALIZER,
  FactoryProvider,
  Inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import { GlobalAction, NgRxState } from '@xbaht/common/modules/app-ngrx.module';

import { LocalizationService } from './utils/services/localization.service';

import { locales } from './views/localizations/common';

import { storage } from './utils/constants/common';
import { languages } from './utils/constants/languages';

import { environment } from 'environments/environment';

export function initialize(
  initializeService: InitializeService
): () => Observable<void> {
  return () => initializeService.initialize();
}

@Injectable({
  providedIn: 'root',
})
export class InitializeService {
  constructor(
    @Inject(PLATFORM_ID) private pid: object,
    private ngrxStore: Store<NgRxState>,
    private localizationService: LocalizationService
  ) {}

  public initialize(): Observable<void> {
    return new Observable((subscriber) => {
      if (!environment.production) {
        console.log(
          `App Start!. (XBaht: v${environment.version} [${
            !environment.production ? environment.mode : ''
          }])`
        );
      }

      this.localizationService.addLangs([
        'en-US',
        'sv-SE',
        'da-DK',
        'vi-VN',
        'th-TH',
      ]);
      this.localizationService.translate(...locales);

      if (isPlatformBrowser(this.pid)) {
        const value = localStorage.getItem(storage.language);
        if (value) {
          const language = JSON.parse(value) as {
            name: string;
            code: string;
            locale: string;
            native: string;
            flag: string;
          };
          this.ngrxStore.dispatch(
            GlobalAction.GlobalAppAction.SetLanguage(language)
          );

          this.localizationService.setDefaultLang(language.code);
          this.localizationService.use(language.code);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const language = languages.find((item) => item.code === 'en-US')!;

          this.ngrxStore.dispatch(
            GlobalAction.GlobalAppAction.SetLanguage(language)
          );

          this.localizationService.setDefaultLang(language.code);
          this.localizationService.use(language.code);
        }
      }

      this.ngrxStore.dispatch(GlobalAction.GlobalAppAction.SetStart());

      subscriber.complete();
    });
  }
}

export const InitializeProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initialize,
  deps: [InitializeService],
  multi: true,
};
