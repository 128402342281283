export const locale = {
  lang: 'en-US',
  data: {
    INTRODUCE:
      'Xbaht AB is an Authorised Payment Institution, regulated by the Swedish Financial Supervisory Authority to conduct money transfer in accordance with the Payment Services Act (2010:751)',
    COMPANY: {
      TITLE: 'Contact us',
      CONTACT_US: 'Xbaht AB',
      ADDRESS: 'Isbergs Gata 8C, 211 19 Malmö - Sweden',
      TELEPHONE: 'Tel. +46 1 0750 0750',
      EMAIL: 'hello@xbaht.com',
    },
    USEFULL_LINKS: {
      TITLE: 'Terms',
      TERMS_AND_CONDITIONS: 'Terms and Conditions',
      PRIVACY_POLICY: 'Privacy Policy',
      COMPLAINT: 'Complaint',
      WHISTLEBLOWER: 'Whistleblower',
    },
    POWERED_BY: {
      TITLE: 'Powered By',
    },
    COPYRIGHT:
      'Copyright {{year}} © Xbaht AB | Company Registration No. 559068-4303 | FSA No. 45607',
  },
};
