import { Component, Inject, PLATFORM_ID, signal } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  public state = signal({ browser: false });

  constructor(
    @Inject(PLATFORM_ID) private pid: object,
    private router: Router
  ) {
    this.state.update((value) => ({
      ...value,
      browser: isPlatformBrowser(this.pid),
    }));

    if (isPlatformBrowser(this.pid)) {
      /**
       * Unicons icon refreshed on route change.
       */
      this.router.events.forEach((event) => {
        if (event instanceof NavigationEnd) {
          // window['Unicons']();
        }

        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });

      this.setCookieConsent();
    }
  }

  private setCookieConsent(): void {
    const head = document.getElementsByTagName('head')[0];

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `document.addEventListener('DOMContentLoaded', function () {
      cookieconsent.run({ "notice_banner_type": "interstitial", "consent_type": "express", "palette": "dark", "language": "en", "page_load_consent_levels": ["strictly-necessary"], "notice_banner_reject_button_hide": false, "preferences_center_close_button_hide": false, "page_refresh_confirmation_buttons": false, "website_name": "https://www.xbaht.com", "website_privacy_policy_url": "${document.location.origin}/privacy-policy" });
    });`;

    head.appendChild(script);
  }
}
