import { createAction, props } from '@ngrx/store';

export const APP_SET_START = '[APP][SET]::[Start]';
export const APP_SET_LANGUAGE = '[APP][SET]::[Language]';

export const SetStart = createAction(APP_SET_START);
export const SetLanguage = createAction(
  APP_SET_LANGUAGE,
  props<{
    name: string;
    code: string;
    locale: string;
    native: string;
    flag: string;
  }>()
);
