export const locale = {
  lang: 'vi-VN',
  data: {
    INTRODUCE:
      'Xbaht AB là Tổ chức thanh toán được ủy quyền, được Cơ quan giám sát tài chính Thụy Điển quản lý để thực hiện chuyển tiền theo Đạo luật dịch vụ thanh toán (2010:751)',
    COMPANY: {
      TITLE: 'Liên hệ chúng tôi',
      CONTACT_US: 'Xbaht AB',
      ADDRESS: 'Isbergs Gata 8C, 211 19 Malmö - Sweden',
      TELEPHONE: 'Điện thoại. +46 1 0750 0750',
      EMAIL: 'hello@xbaht.com',
    },
    USEFULL_LINKS: {
      TITLE: 'Điều kiện',
      TERMS_AND_CONDITIONS: 'Các điều khoản và điều kiện',
      PRIVACY_POLICY: 'Chính sách bảo mật',
      COMPLAINT: 'Lời phàn nàn',
      WHISTLEBLOWER: 'Người tố cáo',
    },
    POWERED_BY: {
      TITLE: 'Powered By',
    },
    COPYRIGHT:
      'Bản quyền {{year}} © Xbaht AB | Số đăng ký công ty 559068-4303 | Số FSA 45607',
  },
};
