import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { TranslateModule } from '@ngx-translate/core';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { AppComponent } from './app.component';
import { InitializeProvider } from './initialize';

import { AppRoutingModule } from './modules/app-routing.module';

import { AppNgrxModule } from '@xbaht/common/modules/app-ngrx.module';
import { LoggingFetchProvider } from '@xbaht/common/fetches/interceptors/logging-fetch.service';
import { EventFetchProvider } from '@xbaht/common/fetches/interceptors/event-fetch.service';

import { HeaderComponent } from './views/shared/header/header.component';
import { FooterComponent } from './views/shared/footer/footer.component';

import { MasterPageComponent } from './views/layouts/master-page/master-page.component';

import { SwitcherComponent } from './views/shared/switcher/switcher.component';

import { environment } from 'environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MasterPageComponent,
    SwitcherComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppNgrxModule,
    AppRoutingModule,
    NgxGoogleAnalyticsModule.forRoot(environment.google.ga),
    TranslateModule.forRoot(),
    FeatherModule.pick(allIcons),
    ScrollToModule.forRoot(),
    NgxSkeletonLoaderModule,
  ],
  exports: [FeatherModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    provideClientHydration(),
    InitializeProvider,
    !environment.production ? LoggingFetchProvider : [],
    EventFetchProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
