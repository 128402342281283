export const locale = {
  lang: 'th-TH',
  data: {
    INTRODUCE:
      'Xbaht AB เป็นบริษัทที่จดทะเบียนและได้รับใบอนุญาต<br>ด้านการเงิน ซึ่งควบคุมโดยหน่วยงานกำกับดูแล<br>ทางการเงินของสวีเดน เพื่อดำเนินการโอนเงิน<br>ตามพระราชบัญญัติบริการการชำระเงิน (2010:751)',
    COMPANY: {
      TITLE: 'ติดต่อเรา',
      CONTACT_US: 'Xbaht AB',
      ADDRESS: 'Isbergs Gata 8C, 211 19 Malmö - Sweden',
      TELEPHONE: 'โทร. +46 1 0750 0750',
      EMAIL: 'hello@xbaht.com',
    },
    USEFULL_LINKS: {
      TITLE: 'เงื่อนไข',
      TERMS_AND_CONDITIONS: 'ข้อกำหนดและเงื่อนไข',
      PRIVACY_POLICY: 'นโยบายความเป็นส่วนตัว',
      COMPLAINT: 'ร้องเรียน',
      WHISTLEBLOWER: 'ผู้แจ้งเบาะแส',
    },
    POWERED_BY: {
      TITLE: 'ขับเคลื่อนโดย',
    },
    COPYRIGHT:
      'สงวนลิขสิทธิ์ {{year}} © Xbaht AB | ทะเบียนบริษัทเลขที่ 559068-4303 | สถาบันการเงินเลขที่ 45607',
  },
};
