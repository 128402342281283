export const locale = {
  lang: 'sv-SE',
  data: {
    MENU: {
      SEND_MONEY: 'Skicka pengar',
      HOW_IT_WORKS: 'Hur det fungerar',
      CUSTOMER_SERVICE: 'Få hjälp',
      CONTACT_US: 'Kontakta oss',
    },
  },
};
