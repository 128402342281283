export const locale = {
  lang: 'da-DK',
  data: {
    INTRODUCE:
      'Xbaht AB er en autoriseret betalingsinstitution, reguleret af det svenske finanstilsyn til at udføre pengeoverførsler i overensstemmelse med loven om betalingstjenester (2010:751)',
    COMPANY: {
      TITLE: 'Kontakt os',
      CONTACT_US: 'Xbaht AB',
      ADDRESS: 'Isbergs Gata 8C, 211 19 Malmö - Sweden',
      TELEPHONE: 'Tlf. +46 1 0750 0750',
      EMAIL: 'hello@xbaht.com',
    },
    USEFULL_LINKS: {
      TITLE: 'Betingelser',
      TERMS_AND_CONDITIONS: 'Vilkår og betingelser',
      PRIVACY_POLICY: 'Fortrolighedspolitik',
      COMPLAINT: 'Klage',
      WHISTLEBLOWER: 'Whistleblower',
    },
    POWERED_BY: {
      TITLE: 'Powered By',
    },
    COPYRIGHT:
      'Copyright {{year}} © Xbaht AB | Company Registration No. 559068-4303 | FSA No. 45607',
  },
};
