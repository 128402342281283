export const languages = [
  {
    name: 'English',
    code: 'en-US',
    locale: 'us',
    native: 'English',
    flag: '🇺🇸',
  },
  {
    name: 'Danish',
    code: 'da-DK',
    locale: 'dk',
    native: 'Dansk',
    flag: '🇩🇰',
  },
  {
    name: 'Swedish',
    code: 'sv-SE',
    locale: 'se',
    native: 'Svenska',
    flag: '🇸🇪',
  },
  {
    name: 'Vietnamese',
    code: 'vi-VN',
    locale: 'vn',
    native: 'Tiếng Việt',
    flag: '🇻🇳',
  },
  {
    name: 'Thai',
    code: 'th-TH',
    locale: 'th',
    native: 'ไทย',
    flag: '🇹🇭',
  },
  // {
  //   name: 'Finnish',
  //   code: 'fi-FI',
  //   locale: 'fi',
  //   native: 'Suomi',
  //   flag: '🇫🇮',
  // },
  // {
  //   name: 'Dutch',
  //   code: 'nl-NL',
  //   locale: 'nl',
  //   native: 'Nederlands',
  //   flag: '🇳🇱',
  // },
  // {
  //   name: 'German',
  //   code: 'de-DE',
  //   locale: 'de',
  //   native: 'Deutsch',
  //   flag: '🇩🇪',
  // },
  // {
  //   name: 'French',
  //   code: 'fr-FR',
  //   locale: 'fr',
  //   native: 'Français',
  //   flag: '🇫🇷',
  // },
  // {
  //   name: 'Spanish',
  //   code: 'es-ES',
  //   locale: 'es',
  //   native: 'Español',
  //   flag: '🇪🇸',
  // },
  // {
  //   name: 'Italian',
  //   code: 'it-IT',
  //   locale: 'it',
  //   native: 'Italiano',
  //   flag: '🇮🇹',
  // },
];

// export const languages = [
//   { code: 'af', name: 'Afrikaans', flag: '🇿🇦', englishName: 'Afrikaans' },
//   { code: 'sq', name: 'Shqip', flag: '🇦🇱', englishName: 'Albanian' },
//   { code: 'am', name: 'አማርኛ', flag: '🇪🇹', englishName: 'Amharic' },
//   {
//     code: 'en-AU',
//     name: 'English (Australia)',
//     flag: '🇦🇺',
//     englishName: 'English',
//   },
//   {
//     code: 'en-CA',
//     name: 'English (Canada)',
//     flag: '🇨🇦',
//     englishName: 'English',
//   },
//   {
//     code: 'en-GB',
//     name: 'English (United Kingdom)',
//     flag: '🇬🇧',
//     englishName: 'English',
//   },
//   { code: 'en', name: 'English', flag: '🇺🇸', englishName: 'English' },
//   { code: 'ar', name: 'العربية', flag: '🇸🇦', englishName: 'Arabic' },
//   { code: 'hy', name: 'Հայերեն', flag: '🇦🇲', englishName: 'Armenian' },
//   { code: 'my', name: 'ဗမာ', flag: '🇲🇲', englishName: 'Burmese' },
//   { code: 'eu', name: 'Euskara', flag: '🇪🇸', englishName: 'Basque' },
//   { code: 'bn', name: 'বাংলা', flag: '🇧🇩', englishName: 'Bengali' },
//   { code: 'bg', name: 'Български', flag: '🇧🇬', englishName: 'Bulgarian' },
//   { code: 'be', name: 'Беларуская', flag: '🇧🇾', englishName: 'Belarusian' },
//   { code: 'hr', name: 'Hrvatski', flag: '🇭🇷', englishName: 'Croatian' },
//   { code: 'da', name: 'Dansk', flag: '🇩🇰', englishName: 'Danish' },
//   { code: 'et', name: 'Eesti', flag: '🇪🇪', englishName: 'Estonian' },
//   { code: 'tl', name: 'Filipino', flag: '🇵🇭', englishName: 'Filipino' },
//   { code: 'fi', name: 'Suomi', flag: '🇫🇮', englishName: 'Finnish' },
//   {
//     code: 'fr-FR',
//     name: 'Français (France)',
//     flag: '🇫🇷',
//     englishName: 'French',
//   },
//   {
//     code: 'fr-CA',
//     name: 'Français (Canada)',
//     flag: '🇨🇦',
//     englishName: 'French',
//   },
//   { code: 'gl', name: 'Galego', flag: '🇪🇸', englishName: 'Galician' },
//   { code: 'ka', name: 'ქართული', flag: '🇬🇪', englishName: 'Georgian' },
//   { code: 'gu', name: 'ગુજરાતી', flag: '🇮🇳', englishName: 'Gujarati' },
//   { code: 'he', name: 'עברית', flag: '🇮🇱', englishName: 'Hebrew' },
//   { code: 'hi', name: 'हिन्दी', flag: '🇮🇳', englishName: 'Hindi' },
//   { code: 'id', name: 'Indonesia', flag: '🇮🇩', englishName: 'Indonesian' },
//   { code: 'is', name: 'Íslenska', flag: '🇮🇸', englishName: 'Icelandic' },
//   { code: 'it', name: 'Italiano', flag: '🇮🇹', englishName: 'Italian' },
//   { code: 'ja', name: '日本語', flag: '🇯🇵', englishName: 'Japanese' },
//   { code: 'kn', name: 'ಕನ್ನಡ', flag: '🇮🇳', englishName: 'Kannada' },
//   { code: 'ca', name: 'Català', flag: '🇪🇸', englishName: 'Catalan' },
//   { code: 'kk', name: 'Қазақ тілі', flag: '🇰🇿', englishName: 'Kazakh' },
//   { code: 'km', name: 'ខ្មែរ', flag: '🇰🇭', englishName: 'Khmer' },
//   { code: 'ko', name: '한국어', flag: '🇰🇷', englishName: 'Korean' },
//   { code: 'ky', name: 'Кыргызча', flag: '🇰🇬', englishName: 'Kyrgyz' },
//   { code: 'lo', name: 'ລາວ', flag: '🇱🇦', englishName: 'Lao' },
//   { code: 'lt', name: 'Lietuvių', flag: '🇱🇹', englishName: 'Lithuanian' },
//   { code: 'lv', name: 'Latviešu', flag: '🇱🇻', englishName: 'Latvian' },
//   { code: 'mk', name: 'Македонски', flag: '🇲🇰', englishName: 'Macedonian' },
//   { code: 'ml', name: 'മലയാളം', flag: '🇮🇳', englishName: 'Malayalam' },
//   {
//     code: 'ms-MY',
//     name: 'Bahasa Melayu (Malaysia)',
//     flag: '🇲🇾',
//     englishName: 'Malay (Malaysia)',
//   },
//   { code: 'ms', name: 'Bahasa Melayu', flag: '🇲🇾', englishName: 'Malay' },
//   { code: 'mr', name: 'मराठी', flag: '🇮🇳', englishName: 'Marathi' },
//   { code: 'hu', name: 'Magyar', flag: '🇭🇺', englishName: 'Hungarian' },
//   { code: 'mn', name: 'Монгол', flag: '🇲🇳', englishName: 'Mongolian' },
//   { code: 'ne', name: 'नेपाली', flag: '🇳🇵', englishName: 'Nepali' },
//   { code: 'nl', name: 'Nederlands', flag: '🇳🇱', englishName: 'Dutch' },
//   { code: 'no', name: 'Norsk', flag: '🇳🇴', englishName: 'Norwegian' },
//   { code: 'de', name: 'Deutsch', flag: '🇩🇪', englishName: 'German' },
//   { code: 'pa', name: 'ਪੰਜਾਬੀ', flag: '🇮🇳', englishName: 'Punjabi' },
//   { code: 'fa', name: 'فارسی', flag: '🇮🇷', englishName: 'Persian' },
//   { code: 'pl', name: 'Polski', flag: '🇵🇱', englishName: 'Polish' },
//   {
//     code: 'pt-BR',
//     name: 'Português (Brasil)',
//     flag: '🇧🇷',
//     englishName: 'Portuguese (Brazil)',
//   },
//   {
//     code: 'pt-PT',
//     name: 'Português (Portugal)',
//     flag: '🇵🇹',
//     englishName: 'Portuguese (Portugal)',
//   },
//   { code: 'ro', name: 'Română', flag: '🇷🇴', englishName: 'Romanian' },
//   { code: 'ru', name: 'Русский', flag: '🇷🇺', englishName: 'Russian' },
//   { code: 'rm', name: 'Rumantsch', flag: '🇨🇭', englishName: 'Romansh' },
//   { code: 'si', name: 'සිංහල', flag: '🇱🇰', englishName: 'Sinhala' },
//   { code: 'sk', name: 'Slovenčina', flag: '🇸🇰', englishName: 'Slovak' },
//   { code: 'sl', name: 'Slovenščina', flag: '🇸🇮', englishName: 'Slovenian' },
//   { code: 'sr', name: 'Српски', flag: '🇷🇸', englishName: 'Serbian' },
//   { code: 'sw', name: 'Kiswahili', flag: '🇹🇿', englishName: 'Swahili' },
//   { code: 'ta', name: 'தமிழ்', flag: '🇮🇳', englishName: 'Tamil' },
//   { code: 'te', name: 'తెలుగు', flag: '🇮🇳', englishName: 'Telugu' },
//   { code: 'th', name: 'ไทย', flag: '🇹🇭', englishName: 'Thai' },
//   { code: 'tr', name: 'Türkçe', flag: '🇹🇷', englishName: 'Turkish' },
//   { code: 'uk', name: 'Українська', flag: '🇺🇦', englishName: 'Ukrainian' },
//   { code: 'ur', name: 'اردو', flag: '🇵🇰', englishName: 'Urdu' },
//   { code: 'vi', name: 'Tiếng Việt', flag: '🇻🇳', englishName: 'Vietnamese' },
//   { code: 'zu', name: 'Zulu', flag: '🇿🇦', englishName: 'Zulu' },
//   {
//     code: 'az',
//     name: 'Azərbaycan dili',
//     flag: '🇦🇿',
//     englishName: 'Azerbaijani',
//   },
//   { code: 'cs', name: 'Čeština', flag: '🇨🇿', englishName: 'Czech' },
//   {
//     code: 'zh-HK',
//     name: '中文（香港）',
//     englishName: 'Chinese (Hong Kong)',
//     flag: '🇭🇰',
//   },
//   {
//     code: 'zh-TW',
//     name: '中文（繁體）',
//     englishName: 'Chinese (Traditional)',
//     flag: '🇹🇼',
//   },
//   {
//     code: 'zh',
//     name: '中文（简体）',
//     englishName: 'Chinese (Simplified)',
//     flag: '🇨🇳',
//   },
//   { code: 'el', name: 'Ελληνικά', englishName: 'Greek', flag: '🇬🇷' },
//   {
//     code: 'es-419',
//     name: 'Español (Latinoamérica)',
//     englishName: 'Spanish (Latin America)',
//     flag: '🌎',
//   },
//   {
//     code: 'es-US',
//     name: 'Español (Estados Unidos)',
//     englishName: 'Spanish (United States)',
//     flag: '🇺🇸',
//   },
//   {
//     code: 'es',
//     name: 'Español (España)',
//     englishName: 'Spanish (Spain)',
//     flag: '🇪🇸',
//   },
//   { code: 'sv', name: 'Svenska', englishName: 'Swedish', flag: '🇸🇪' },
// ];
