/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

import {
  GlobalAction,
  GlobalAppState,
  NgRxState,
} from '@xbaht/common/modules/app-ngrx.module';

import { storage } from '../../../utils/constants/common';
import { languages } from '../../../utils/constants/languages';
import { LocalizationService } from '../../../utils/services/localization.service';

import { locales } from '../../localizations/header';

/***
 * Header Component
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() public navClass!: string;
  @Input() public buttonList!: boolean;
  @Input() public sliderTopbar!: boolean;
  @Input() public isdeveloper!: boolean;
  @Input() public shopPages!: boolean;
  @Input() public Settingicon!: boolean;
  @Input() public appicons!: boolean;
  @Input() public Nfticons!: boolean;
  @Input() public Menuoption!: string;
  @Input() public Language!: boolean;

  public app$!: Observable<GlobalAppState>;

  public languages = languages;

  public href = '';

  constructor(
    private router: Router,
    private ngrxStore: Store<NgRxState>,
    private localizationService: LocalizationService,
    private modalService: NgbModal,
    private offcanvasService: NgbOffcanvas
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
      }
    });
  }

  public isCondensed = false;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._activateMenuDropdown();
    }, 0);
  }

  ngOnInit(): void {
    this.app$ = this.ngrxStore.select('App');

    this.localizationService.translate(...locales);

    this.href = this.router.url;
    if (this.router.url == '/index-classic-saas') {
      const light_btn = document.querySelectorAll('.login-btn-primary');
      light_btn.forEach((element) => {
        (element as HTMLElement).style.display = 'none';
      });

      const dark_btn = document.querySelectorAll('.login-btn-light');
      dark_btn.forEach((element) => {
        (element as HTMLElement).style.display = 'block';
      });
    } else if (this.router.url == '/index-apps') {
      document.querySelector('.app-header')?.classList.remove('d-none');
    } else {
      const light_btn = document.querySelectorAll('.login-btn-primary');
      light_btn.forEach((element) => {
        (element as HTMLElement).style.display = 'block';
      });

      const dark_btn = document.querySelectorAll('.login-btn-light');
      dark_btn.forEach((element) => {
        (element as HTMLElement).style.display = 'none';
      });
    }

    setTimeout(() => {
      this._activateMenuDropdown();
    }, 0);
  }

  public _activateMenuDropdown(): void {
    /**
     * Menu activation reset
     */
    const resetParent = (el: any) => {
      el.classList.remove('active');
      const parent = el.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.remove('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.remove('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.remove('active');
            }
          }
        }
      }
    };
    const links = document.getElementsByClassName('nav-link-ref') as any;
    let matchingMenuItem = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }
    for (let i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i]['pathname']) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active');
      const parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add('active');

              document.getElementById('navigation')!.style.display = 'none';
              this.isCondensed = false;
            }
          }
        }
      }
    }
  }

  /**
   * Window scroll method
   */
  // tslint:disable-next-line: typedef
  public windowScroll(): void {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById('topnav')?.classList.add('nav-sticky');
    } else {
      document.getElementById('topnav')?.classList.remove('nav-sticky');
    }
    if (document.getElementById('back-to-top')) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById('back-to-top')!.style.display = 'inline';
      } else {
        document.getElementById('back-to-top')!.style.display = 'none';
      }
    }
  }
  /**
   * Toggle menu
   */
  public toggleMenu(): void {
    this.isCondensed = !this.isCondensed;
    if (this.isCondensed) {
      document.getElementById('navigation')!.style.display = 'block';
    } else {
      document.getElementById('navigation')!.style.display = 'none';
    }
  }

  /**
   * Menu clicked show the submenu
   */
  public onMenuClick(event: any): boolean {
    event.preventDefault();
    const nextEl = event.target.nextSibling.nextSibling;
    if (nextEl && !nextEl.classList.contains('open')) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove('open');
      }
      nextEl.classList.add('open');
    } else if (nextEl) {
      nextEl.classList.remove('open');
    }
    return false;
  }

  public developerModal(content: any): void {
    this.modalService.open(content, { size: 'lg', centered: true });
  }

  public wishListModal(content: any): void {
    this.modalService.open(content, { centered: true });
  }

  // Demo Offcanvas
  public openright(content: TemplateRef<any>): void {
    this.offcanvasService.open(content, { position: 'end' });
  }

  public onClickLanguage(language: {
    name: string;
    code: string;
    locale: string;
    native: string;
    flag: string;
  }): void {
    this.ngrxStore.dispatch(GlobalAction.GlobalAppAction.SetLanguage(language));

    localStorage.setItem(storage.language, JSON.stringify(language));

    this.localizationService.setDefaultLang(language.code);
    this.localizationService.use(language.code);
  }
}
