import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import {
  routerReducer,
  RouterReducerState,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import {
  StoreDevtoolsModule,
  StoreDevtoolsOptions,
} from '@ngrx/store-devtools';

import { GlobalAppAction } from '../stored/global/actions';
import { GlobalAppReducer } from '../stored/global/reducers';
import { GlobalAppState } from '../stored/global/states';

import { environment } from 'environments/environment';

export { GlobalAppState };

interface NgRxState {
  Router: RouterReducerState;
  App: GlobalAppState;
}

const NgRxReducer = {
  Router: routerReducer,
  App: GlobalAppReducer,
};

const GlobalReducer = {
  routerReducer,
  GlobalAppReducer,
};

const GlobalAction = {
  GlobalAppAction,
};

export { NgRxState, NgRxReducer, GlobalReducer, GlobalAction };

const storeDevOpts: StoreDevtoolsOptions = {
  maxAge: 32,
  logOnly: false,
};

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(NgRxReducer),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument(storeDevOpts) : [],
  ],
  exports: [
    StoreModule,
    StoreRouterConnectingModule,
    !environment.production ? StoreDevtoolsModule : [],
  ],
})
export class AppNgrxModule {}
