import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MasterPageComponent } from '../views/layouts/master-page/master-page.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../views/pages/indexs/indexs.module').then(
            (m) => m.IndexsModule
          ),
      },
      {
        path: 'terms',
        loadChildren: () =>
          import('../views/pages/terms-conds/terms-conds.module').then(
            (m) => m.TermsCondsModule
          ),
      },
      {
        path: 'privacy-policy',
        loadChildren: () =>
          import(
            '../views/pages/privacies-policies/privacies-policies.module'
          ).then((m) => m.PrivaciesPoliciesModule),
      },
      {
        path: 'complaint',
        loadChildren: () =>
          import('../views/pages/complaints/complaints.module').then(
            (m) => m.ComplaintsModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      scrollOffset: [0, 0],
      // Enable scrolling to anchors
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
