import { Action, createReducer, on } from '@ngrx/store';

import { GlobalAppAction } from '../actions';
import { GlobalAppState } from '../states/app.state';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import dayjs from 'dayjs';

const initialState: GlobalAppState = {
  ready: false,
  language: null,
  timestamp: '',
};

const appReducer = createReducer(
  initialState,
  on(GlobalAppAction.SetStart, (state) => ({
    ...state,
    ready: true,
    timestamp: dayjs().format(),
  })),
  on(GlobalAppAction.SetLanguage, (state, language) => ({
    ...state,
    language,
  }))
);

export function GlobalAppReducer(
  state: GlobalAppState | undefined,
  action: Action
) {
  return appReducer(state, action);
}
