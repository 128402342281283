export const locale = {
  lang: 'vi-VN',
  data: {
    MENU: {
      SEND_MONEY: 'Gửi tiền',
      HOW_IT_WORKS: 'Làm thế nào nó hoạt động',
      CUSTOMER_SERVICE: 'Được giúp đỡ',
      CONTACT_US: 'Liên hệ chúng tôi',
    },
  },
};
