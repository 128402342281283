/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component } from '@angular/core';

@Component({
  selector: 'app-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.scss'],
})
export class MasterPageComponent {
  public addclass!: string;
  public buttonShow!: boolean;
  public TopbarShow!: boolean;
  public footerClass!: string;
  public developerPage!: boolean;
  public hideFooter!: boolean;
  public shopPages!: boolean;
  public Settingicon!: boolean;
  public appicons!: boolean;
  public Nfticons!: boolean;
  public Menuoption!: string;
  public Language!: boolean;

  constructor(private cdr: ChangeDetectorRef) {}

  /**
   * Router activation
   */
  public onActivate(componentReference: any) {
    this.addclass = componentReference.navClass;
    this.buttonShow = componentReference.buttonList;
    this.TopbarShow = componentReference.sliderTopbar;
    this.footerClass = componentReference.footerVariant;
    this.developerPage = componentReference.isdeveloper;
    this.hideFooter = componentReference.hideFooter;
    this.shopPages = componentReference.shopPages;
    this.Settingicon = componentReference.Settingicon;
    this.appicons = componentReference.appicons;
    this.Nfticons = componentReference.Nfticons;
    this.Menuoption = componentReference.Menuoption;
    this.Language = componentReference.Language;

    this.cdr.detectChanges();
  }
}
